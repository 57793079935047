import './LandingPage.css'
import Logo1 from './Logo/logo1.png'
import facebook from './Logo/facebook.png'
import instagram from './Logo/instagram.png'

function LandingPage (){

    const facebookHandleClick = () => {
        window.open("https://www.facebook.com/My-Ads-Guru-111235794862107");
      };
    
      const instagramHandleClick = () => {
        window.open("https://www.instagram.com/my_ads_guru/");
      };
    
    return(
        <>
        <div className='LandingPage'>
            <img id="logo1" src={Logo1} alt="Logo" />
            <h1 id="title-LandingPage">My Ads Guru</h1>
            <div className='social-icon'>
            <img id="facebook-landingPage"  src={facebook} alt="Logo"  onClick={facebookHandleClick} />
            <img id="instagram-landingPage" src={instagram} alt="Logo" onClick={instagramHandleClick}/>
            </div>
            <p id="Note">Many More Services coming soon..!!</p>
        </div>
        
        
        </>
    )
}

export default LandingPage;